import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import ContactCreativeForm from './parts/ContactCreativeForm.js'
import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'

const particlesInit = async (main) => {
  await loadFull(main)
}

const particlesLoaded = (container) => {}
class ContactCreative extends React.Component {
  render() {
    // remove   background-color: #000;
    const Section = styled.section`
      position: relative;
      overflow: hidden;
    
      padding: 100px 0;
    `

    const FormRow = styled(Row)`
      background-color: #111;
    `

    const ContactCol = styled(Col)`
      min-height: 600px;
      max-height: 600px;
      padding: 0;
      display: flex;
      align-items: center;
    `

    const Map = styled.iframe`
      border: none;
      height: 100%;
      width: 100%;
    `

    const IconRow = styled(Row)`
      margin-top: 150px;
    `

    const IconCol = styled(Col)`
      @media (max-width: 500px) {
        margin-bottom: 140px;
      }
    `

    const IconContainer = styled.div`
      width: 150px;
      height: 150px;
      margin: auto;
      padding: 35px;
      text-align: center;
      position: relative;
      bottom: 75px;
      background-color: #29aacb;
      border-radius: 150px;
      transition: 0.5s;
    `

    const InfoPart = styled.div`
      min-height: 250px;
      background-color: #111;
      border: 2px solid #444;
      &:hover ${IconContainer} {
        background-color: #009e9e;
      }
    `
    const Icon = styled.img`
      height: 70px;
      width: 70px;
      object-fit: contain;
    `

    const InfoTitle = styled.h4`
      font-size: 35px;
      color: #fff;
      font-family: Teko;
      text-align: center;
    `

    const Info = styled.div`
      position: relative;
      bottom: 30px;
    `

    const InfoLinkContainer = styled.div`
      text-align: center;
    `

    const InfoLink = styled.a`
      color: #29aacb;
      transition: 0.5s;
      &:hover {
        color: #fff;
        text-decoration: none;
      }

      
    `

    const Overlay = styled.div`
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            background-color: rgba(0,0,0,.9);
        `

    return (
      <Section id='contact'>   
        <Container>
          <AnimationContainer animation='fadeIn'>
            <FormRow>
              <ContactCol md={6}>
                <ContactCreativeForm />
              </ContactCol>
              <ContactCol md={6}>
                <Map
                  title='map'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53278.84063628436!2d-70.55255571813399!3d-33.42513342986975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cc2ec8c925bd%3A0x82ed9a4180d925d0!2sLas%20Condes%2C%20Santiago%20Metropolitan%20Region%2C%20Chile!5e0!3m2!1sen!2sse!4v1632995386641!5m2!1sen!2sse'
                />
              </ContactCol>
            </FormRow>
          </AnimationContainer>
          <IconRow style={{ marginTop: '100px' }}>
            <IconCol md={4}>
              <AnimationContainer animation='fadeIn' delay={500}>
                <InfoPart>
                  <IconContainer>
                    <Icon
                      src={
                        'https://abstract-r3tr0.vercel.app/static/7367adf6c5ac8609b6788aba16783d08/46604/email2.png'
                      }
                      alt='email'
                    />
                  </IconContainer>
                  <Info>
                    <InfoTitle>Email</InfoTitle>
                    <InfoLinkContainer>
                      <InfoLink href='mailto:contact@goatlab.io'>
                        contact@goatlab.io
                      </InfoLink>
                    </InfoLinkContainer>
                  </Info>
                </InfoPart>
              </AnimationContainer>
            </IconCol>
            <IconCol md={4}>
              <AnimationContainer animation='fadeIn' delay={1000}>
                <InfoPart>
                  <IconContainer>
                    <Icon
                      src={
                        'https://abstract-r3tr0.vercel.app/static/dd8c3b978734a88dea4f7f8659f39eb2/26bd1/phone.png'
                      }
                      alt='phone'
                    />
                  </IconContainer>
                  <Info>
                    <InfoTitle>Phone</InfoTitle>
                    <InfoLinkContainer>
                      <InfoLink href='tel:+56993254385'>
                        +(56) 99 -3254385
                      </InfoLink>
                    </InfoLinkContainer>
                  </Info>
                </InfoPart>
              </AnimationContainer>
            </IconCol>
            <IconCol md={4}>
              <AnimationContainer animation='fadeIn' delay={1500}>
                <InfoPart>
                  <IconContainer>
                    <Icon
                      src={
                        'https://abstract-r3tr0.vercel.app/static/d000e241bf9adca38ff6552446240db0/46604/map.png'
                      }
                      alt='map'
                    />
                  </IconContainer>
                  <Info>
                    <InfoTitle>Address</InfoTitle>
                    <InfoLinkContainer>
                      <InfoLink
                        target='_blank'
                        href='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53278.84063628436!2d-70.55255571813399!3d-33.42513342986975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cc2ec8c925bd%3A0x82ed9a4180d925d0!2sLas%20Condes%2C%20Santiago%20Metropolitan%20Region%2C%20Chile!5e0!3m2!1sen!2sse!4v1632995386641!5m2!1sen!2sse'
                      >
                        Las Condes, Santiago
                      </InfoLink>
                    </InfoLinkContainer>
                  </Info>
                </InfoPart>
              </AnimationContainer>
            </IconCol>
          </IconRow>
        </Container>
      </Section>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        emailIcon: file(relativePath: { eq: "icons/email2.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mapIcon: file(relativePath: { eq: "icons/map.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        phoneIcon: file(relativePath: { eq: "icons/phone.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
    `}
    render={({ emailIcon, mapIcon, phoneIcon }) => (
      <ContactCreative
        emailIcon={emailIcon}
        mapIcon={mapIcon}
        phoneIcon={phoneIcon}
        {...props}
      />
    )}
  />
)
